<template>
  <div>
    <!-- <van-button @click="openApp()">点击打开app</van-button> -->
    <van-overlay :show="true">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        "
      >
        <van-loading color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "sharePage",
  props: {},
  components: {},
  data() {
    return {
      code: null,
    };
  },
  created() {
    this.code = this.$route.query.code;
  },
  mounted() {
    if(this.$store.state.isIOS) {
      this.openApp("yd20220209://QRcode?code=" + this.code, true);
    }
    if(this.$store.state.isAndroid) {
      this.openApp("ydinvitecode://QRcode?code=" + this.code, true);
    }
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang='less' scoped>
.van-overlay {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>